<template>
  <div class=container>
      <table width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
</td>
          </tr>

                  <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><strong>Building Corner</strong></td>
                      <td><div align="right"><strong>Method :   Line Occluder<br>
                      </strong></div></td>
                    </tr>
                  </tbody></table>
                    <div align="right"></div></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Nook_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Nook_Scene.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Scene</strong></div></td>
                      <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Nook_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Nook_Direct.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Direct</strong></div></td>
                      <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Nook_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Nook_Global.jpg" width="203" height="152" border="0"></a><br>
                              <span class="style1">.</span><br>
                              <strong>Global</strong></div></td>
                    </tr>
                  </tbody></table>
                    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td>This  scene of a building corner lit by the sun is scanned using a stick  occluder. Notice the sharp shadows cast by the sun in the direct  image.&nbsp; The global component on the other hand captures  interreflections between the walls as well as the illumination received  from the sky.</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                  </tbody></table></td>
              </tr>
              <tr>
                <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                    <tbody><tr>
                      <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Nook_Occluder.jpg" width="203" height="152"></td>
                      <td width="68%" valign="top"><p><strong>Captured Video: </strong>This video shows the scanning of the scene using a stick and was used to compute the direct and global images. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Nook.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Nook.mov">Quicktime</a></p></td>
                    </tr>
                </tbody></table></td>
              </tr>
            </tbody></table></td>
          </tr>
 
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><strong>Curtain</strong></td>
                        <td><div align="right"><strong>Method : Mesh Occluder</strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Fabric2_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/image3251.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Fabric2_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/image3121.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Fabric2_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/image6631.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>This  curtain with folds was illuminated by a halogen lamp and scanned using  a mesh occluder. The global image includes interreflections within the  folds as well as translucency effects due to the fine spaces between  the weaves of the fabric.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                      <tbody><tr>
                        <td width="32%"><img src="thumbnails/image6171.jpg" width="203" height="152"></td>
                        <td width="68%" valign="top"><p><strong>Captured Video:</strong> This video shows the scene being scanned using shadow cast by a mesh occluder. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Curtain_640x480.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Curtain_640x480.mov">Quicktime</a></p></td>
                      </tr>
                  </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          

          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><strong>Curtain</strong></td>
                        <td><div align="right"><strong>Method : Mesh Occluder</strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/ShowerCurtain_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6781.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/ShowerCurtain_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6801.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/ShowerCurtain_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6861.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>In  this scene, a mannequin is placed just behind a translucent shower  curtain. The mannequin is not directly lit by the source but by the  light diffused through the shower curtain. The direct image only  includes reflections from the shower curtain and not the mannequin. The  global component reveals the mannequin behind the curtain.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                      <tbody><tr>
                        <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image6881.jpg" width="203" height="152"></td>
                        <td width="68%" valign="top"><p><strong>Captured Video: </strong>This video shows the scene being scanned using shadow cast by a mesh occluder. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/ShowerCurtain_640x480.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/ShowerCurtain_640x480.mov">Quicktime</a></p></td>
                      </tr>
                  </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          

          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><strong>Red leaves</strong></td>
                        <td><div align="right"><strong>Method : Line Occluder</strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Red_Leaf_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Red_Leaf_Scene.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Red_Leaf_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Red_Leaf_Direct.jpg" width="202" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Red_Leaf_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Red_Leaf_Global.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>This  scene of red leaves is lit by the sun and is scanned using a thin stick  occluder. The direct image has sharp and dark shadows. The global  image, on the other hand, has a glowing effect due to the diffusion of  light through the translucent leaves. It also includes the illumination  of the leaves by the sky and surrounding buildings. Note that the green  leaf beneath the red leaves (in the shadow area) does not receive any  direct illumination from the sun and hence is visible only in the  global image.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                      <tbody><tr>
                        <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Red_Leaves_Occluder.jpg" width="202" height="152"></td>
                        <td width="68%" valign="top"><p><strong>Captured Video:</strong> This video shows the scanning of the scene using a stick and was used to compute the direct and global images. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Red_Leaves.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Red_Leaves.mov">Quicktime</a></p></td>
                      </tr>
                  </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          

          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {
    name:'test'
}
</script>

<style>

</style>